.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourService {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ourServiceBox {
  margin-bottom: 3rem;
  width: 50%;
  position: relative;
}

.ourServiceBox h1 {
  color: #042B3B;
  font-size: 36px;
  padding: 12px;
  font-weight: bold;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.ourServiceBox h1::after {
  content: '';
  display: block;
  width: 50%;
  height: 5px;
  background-color: #E6AF4B;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  border-radius: 5px;
}

.h5Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ourServiceBox h5 {
  font-weight: 300;
  font-size: 18px;
  color: #042B3B;
  width: 48%;
  line-height: 1.5;
}

.english {
  text-align: center;
}

.thai {
  text-align: center;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btnContainer button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border: 1px solid #042B3B;
  background-color: transparent;
  color: #042B3B;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btnContainer button img {
  width: 24px;
  height: 24px;
}

.btnContainer button:hover {
  background-color: #042B3B;
  color: #fff;
}

.btnContainer button.active {
  background-color: #042B3B;
  color: #fff;
}

.ourServiceBox2 {
  display: flex;
  align-items: center;
  width: 80%;
  padding: 12px;
  color: #042B3B;
  transition: all 0.1s ease;
  line-height: 1.4;
  border-radius: 10px;
}

.ourServiceBox2 .serviceImage {
  flex-shrink: 0;
  width: 50%;
  height: 500px;
  margin-right: 20px;
}

.ourServiceBox2 .serviceText {
  width: 50%;
  flex-grow: 1;
}

.ourServiceBox2 h1 {
  text-align: left;
  font-size: 24px;
  color: #042B3B;
}

.ourServiceBox2 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin: 12px 0;
}

.ourServiceBox2 a {
  color: #E6AF4B;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  margin-top: 12px;
}

.ourServiceBox2 a:hover {
  color: #042B3B;
}

.ourServiceBox2 a:before,
.ourServiceBox2 a:after {
  content: "";
  position: absolute;
  width: 77px;
  height: 3px;
  bottom: -6px;
  left: 0;
  background-color: #E6AF4B;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}

.ourServiceBox2 a:hover:before,
.ourServiceBox2 a:hover:after {
  visibility: visible;
  transform: scaleX(1);
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 100%;
    padding: 1rem;
    height: auto;
  }
  .ourServiceBox {
    width: 80%;
  }
  .ourServiceBox2 {
    width: 90%;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
  .ourServiceBox2 .serviceImage,
  .ourServiceBox2 .serviceText {
    width: 50%;
    height: auto;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 1rem;
    height: auto;
  }
  .ourServiceBox {
    width: 90%;
  }

  .ourServiceBox h1 {
    font-size: 24px;
    margin: 0;
  }
  .ourServiceBox2 {
    align-items: center;
    text-align: center;

  }
  .ourServiceBox2 .serviceImage,
  .ourServiceBox2 .serviceText {
    width: 50%;
    height: auto;
    margin: 0;
  }
  .h5Container {
    flex-direction: column;
    align-items: center;
  }
  .h5Container h5 {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .btnContainer {
    flex-direction: column;
    align-items: center;
  }

  .h5Container {
    display: none;
  }

  .btnContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }


  .ourServiceBox2 {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .serviceImage {
    display: none;
  }
  .ourServiceBox2 .serviceImage,
  .ourServiceBox2 .serviceText {
    width: 100%;
    height: auto;
    margin: 0;
  }
  .h5Container {
    flex-direction: column;
    align-items: center;
  }
  .h5Container h5 {
    width: 100%;
    margin-bottom: 1rem;
  }
}
