.faq {
    height: 100%;
    z-index: 1;
    margin-top: 2rem;
}

.faq h1{
font-weight: 600;
font-size: 36px;
color: #042B3B;
text-align: left;
padding: 12px;
}