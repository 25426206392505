.blogShare {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-top: .8px dotted #333333;
    margin-bottom: 1rem;
}

.blogShareIcon {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
}

.shareIcon {
    display: flex;
    align-items: center;
    background: none !important;
    border: 1px solid #ececec !important;
    border-radius: 10px !important;
    padding: 8px !important;
    gap: 8px !important;
    margin-left: 8px !important;
    font-size: 12px;
    color: #333;
    cursor: pointer;
}

.shareIcon:hover {
    background: #d1d1d1 !important;
}

@media screen and (max-width: 480px) { 
     .blogShare {
        justify-content: space-around;
     }   
}
