.courierHeader {
    position: relative;
    height: 350px;
    width: 100%;
    background-color: #042B3B;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
  }
  
  

.courierHeaderText {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

 
.courierHeaderText h1 {
    color: #E6AF4B; 
    font-size: 64px;
    margin-bottom: 1rem;
}

.courierHeaderText h3 {
    width: 70%;
    font-size: 28px;
    color: white;
}





.courier1 {
    display: flex !important;
    position: relative;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.courier1Title {
    margin-top: 2rem;
    text-align: center;
}

.courier1Title h1 {
    font-size: 32px;
    color: #042B3B;
}


.courierTable {
    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: repeat(2, 1fr);
    z-index: 20;
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
    background: #fafafa;
    margin: 1rem 0;


}   

.table-box {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top: .1px dotted #333333;
    border-left: .1px dotted #333333;
}



.boxBg {
    position: absolute;
    height: 100%;
    width: 100%;

}


.boxBg img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.boxDetail {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 600;
    height: 100%;
    width: 90%;
    color: #042B3B;
}

.boxDetail p {
    padding: 12px;
    margin-top: 2rem;
    line-height: 1.4;
    font-weight: 600;
    height: 20%;
    width: 100%;
}




.contact-box {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 350px;
    height: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top: .1px dotted #333333;
    border-left: .1px dotted #333333;
}


.contactBoxTitle {
    display: flex;
    width: 100%;
    margin-top: 3rem;
}
.contactBoxTitle p {
    font-weight: bold;
    color: #042B3B;
    font-size: 14px;
}




.courier2 {
    display: flex !important;
    position: relative;
    height: 550px;
    width: 100%;
    margin: 5rem 0;
    gap: 2rem;
}

.courier2Left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    position: relative;
    height: 100%;
    width: 50%;
}

.courier2Left h1 {
    color: #042B3B;    
    width: 80%;
    font-weight: 600;
    font-size: 36px;
    position: relative;
}

.courier2Left h1::after {
    content: '';
    display: block;
    width: 72%;
    height: 5px;
    background-color: #E6AF4B;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 30%;
    bottom: -20px;
    border-radius: 5px;
  }

.courier2Left p {
    color: #042B3B;    
    font-size: 20px;
    width: 80%;
    padding: 12px 0;
    line-height: 1.75;
}

.courier2btn {
    width: 80%;
}




.courier2img {
    display: flex;
    height: 100%;
    width: 50%;


}

.courier2img img {
    height: 100%;
    width: 100%;
    object-fit: cover;

}



.faq-1 {
    height: 80vh;
    width: 100%;
}

.courierFaq h1 {
    text-align: center;
    font-size: 32px;
    color: #042B3B;
    font-weight: 600;
}

.courierFaq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


.faqSum {
    width: 100%;
}
.faqSum:hover {
    background-color: #f4f6f8;
}


.courierFaq h3 {
    font-size: 20px;
}

.courierFaq p {
    font-size: 18px;
    padding: 6px;
    flex-shrink: 1;
}



.courierSlider {
    height: 100%;
    text-align: center;
    margin-top: 4rem;
}

.courierSlider h1 {
    font-size: 32px;
    color: #042B3B;
    font-weight: 600;

}





.cbBtn{
    padding: 6px 12px;
        background-color: transparent;
        box-shadow: 0px 0px 5px #e6b04b95;
        border: .2px solid #E6AF4B;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 22px;
        transition: background-color 0.5s ease, color 0.5s ease;
        color: #E6AF4B !important;
}

.cbBtn:hover {
    transform: scale(1.05);
    background-image: linear-gradient(to bottom, #E6AF4B, #F9D49C);    transition: 0.5s ease;
    color: #042B3B !important;
}


.customscc1 {
    display: flex !important;
    flex-direction: row-reverse !important;
    width: 100%;
    height: 500px;
}

.leftcc1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    gap: 12px;
}

.leftcc1 h4 {
    width: 80%;
    text-align: left;
    font-weight: 400;
    color: #E6AF4B;
    font-size: 24px;
}


.leftcc1 h2 {
    font-size: 32px;
    width: 80%;
    color: #042B3B;
}

.leftcc1 p {
    color: #042B3B;
    font-size: 18px;
    width: 80%;
    font-weight: 400;
}


.rightcc1 {
    width: 50%;
}

.rightcc1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}






@media only screen and (max-width: 1024px ) {
    .courierTable {
        grid-template-columns: repeat(2, 1fr); 
        grid-template-rows: repeat(3, 1fr); 
    }


}



@media only screen and (max-width: 480px ) {

    .courierHeaderText h1 {
        width: 80%;
        font-size: 36px;
    }

    .courierHeaderText h3 {
        font-size: 18px;
        width: 80%;
    }



    .courierTable {
        grid-template-columns: repeat(1, 1fr); 
        grid-template-rows: repeat(1, 1fr); 
    }

    .contact-box {
        display: none;
    }

    .table-box{
        height: 300px;
    }

    .boxTitle {
        font-size: 32px;
    }
    
    .boxDetail li{
        font-size: 16px;
    }

    .boxDetail p{
        padding: 2px 0;
        text-align: center;
        width: 80%;
    }
    

    .courier1Title {
        margin-top: 0;
    }

    .courier1Title h1 {
        font-size: 32px;
    }


    
    .courier2 {
        height: 100%;
        flex-direction: column-reverse;
        margin-bottom: 0;
    }

    .courier2Left {
        width: 100%;
    }

    .courier2img {
        width: 100%;
    }

    .courier2Left p {
        width: 100%;
        padding: 12px 0;
        margin-right: 0;
        font-size: 16px;

    }

    .courier2Left h1 {
        width: 100%;
        font-size: 24px;
    }

    .courier2Left h3 {
        font-size: 24px;
        margin-top: 2rem;
    }



    .faq-1 {
        height: 100%;
        margin-top: 4rem;
    }


    .courierFaq {
        width: 100%;
        margin-right: 0;
    }

    .courierFaq p {
        font-size: 16px;
    }



    .courierSlider {
        font-size: 18px;
        margin-top: 4rem;
    }


    .customscc1 {
        flex-direction: column-reverse !important;
        height: 100%;
        margin-bottom: 5rem;
    }

    .leftcc1 {
        width: 100%;
    }

    .leftcc1 h4 {
        width: 100%;
    }

    .leftcc1 h2 {
        font-size: 24px;
        width: 100%;
        font-weight: 400;
    }

    .leftcc1 p {
        font-size: 18px;
        width: 100%;
    }


    .rightcc1 {
        width: 100%;
    }

    
    
}