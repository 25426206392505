/* Add CSS styles for the Privacypolicy component here */

.privacypolicy {

    display: flex;
    position: relative;
  }

  .privacypolicyBody {
    margin-top: 4rem;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    border-radius: 20px;
    color: #042B3B;

    
  }
  
  .privacypolicy h2 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .privacypolicy h5 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 12px;

  }
  
  .privacypolicy p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  /* Style the list items */
  .privacypolicy ul {
    list-style: disc;
    padding-left: 20px;
  }
  
  /* Style the contact information */
  .privacypolicy .contact-info {
    font-weight: 400;
    color: #e6af4b;
  }
  
  /* Add more styles as needed */
  