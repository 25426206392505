.result-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    color: #042B3B;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    max-width: 100%;
    margin: 20px auto;
}


.shipment-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 150px;
    margin-top: 12px;
  }
  
  .shipment-id .shipment-header h3 {
  margin-bottom: 10px;
  font-size: 2.2rem;
  color: #333;
  font-weight: 500;
  text-transform: uppercase;
}


  .shipment-id span {
    font-size: 36px;
    font-weight: 300;
  }

  .shipment-id span.shipment-route {
    font-size: 36px;
    font-weight: 300;
    display: flex;
    align-items: center;
  }
  
  .shipment-id span.shipment-route .icon {
    font-size: 2rem; /* Adjust the size as needed */
    margin-right: 10px; /* Adjust the spacing as needed */
  }

  
  
  .shipment-id p {
    padding: 12px 0;
  }
  
  .shipment-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .icon-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: none;
    cursor: pointer;
    border: 1px solid #042B3B;
    border-radius: 5px;
    color: #042B3B;
    font-size: 0.8rem;
    transition: background-color 0.3s;
  }
  
  .icon-button:hover {
    background-color: #063A4F;
    color: #f4f6f8;
  }


.progress-info {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  
  /* Add this to your existing CSS file */
.progress-container {
    position: relative;
    width: 100%;
    margin-top: 42px;
    height: 50px; /* Adjust the height as needed */
  }
  
  .status-icon-container {
    position: absolute;
    top: -20px; /* Adjust to align icons vertically */
    transform: translateX(-50%);
  }
  
  .shipping-icon {
    font-size: 2rem !important; /* Adjust the size as needed */
    border-radius: 50%;
    background-color: white; /* Or use your image as background */
    padding: 0.5rem;
    border: 2px solid #042B3B;
  }
  
  .shipping-icon.completed {
    color: #007F5F;
    border: 2px solid #007F5F;

  }

  .status-icon.completed {
    color: #007F5F; /* Green color for completed steps */
  }
  
  .status-icon.delayed {
    color: red; /* Red color for delayed steps */
  }
  
  .status-line {
    width: 4px;
    height: 80px;
    background-color: grey;
  }
  
  .status-line.completed {
    background-color: #00B894;
  }
  

  /*  Shipment Container  */
  
.shipment-info-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
}

/* Left column */

.shipment-status {
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #f4f4f4;
    height: 650px;
  }

  .shipment-status h3 {
    margin-top: 10px;
    font-size: 1.5rem;
    color: #333;
    font-weight: 400;
  }

  .shipment-status span {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #555;
    text-decoration: underline;

  }


  .status-update {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 150px;
    border-bottom: .8px solid grey;
  }
  
  .status-date {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  
  .status-date p {
    margin-top: 4px;
    padding: 0;
  }

  
  .status-date .status-delayed{
    font-size: 12px;
    border-radius: 50px;
    width: 70px;
    color: white;
    background-color: rgb(237, 94, 94);
  }
  
  .status-progress {
    flex: 0 0 50px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  
  .status-icon {
    font-size: 3rem !important;
    border-radius: 50%;
    color: grey; 
    padding: 0.5rem;
  }


  .status-icon.completed {
    color: #007F5F;
  }
  
  
  .status-icon.delayed {
    color: red; 
  }
  
  
  .status-detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  
  .status-detail h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #555;
    font-weight: 500;
    text-align: left;
    width: 50%;
  }

  .status-detail h4.completed {
    font-weight: bold;
    color: #007F5F;
  }

  .status-detail ul {
    text-align: left;
    width: 50%;
  }

  .status-detail li {
    margin: 6px 0 0;
    font-size: 0.9rem;
    color: #333;
    padding: 0;
}


/* Right column info */

.shipment-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    background-color: #f4f4f4;
    height: 650px;
}

.shipment-info h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
  }
  
  .shipment-info p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.2;
    font-weight: 500;
  }
  
  .shipment-info strong {
    color: #333;
    font-weight: 300;
    font-size: 0.9rem;
    display: block;
    margin-bottom: 5px;

  }

  .shipment-info button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #042B3B ;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.shipment-info button:hover {
    background-color: #063A4F;
}


/* Responsive Design */

@media (max-width: 1024px) {
  .result-container {
      flex-direction: column;
      padding: 15px;
  }

  .shipment-header {
      flex-direction: column;
      align-items: flex-start;
  }

  .shipment-id span,
  .shipment-id span.shipment-route {
      font-size: 28px;
  }

  .shipment-id span.shipment-route .icon {
      font-size: 1.5rem;
  }
  
  .shipment-info-container {
    flex-direction: column;
  }

  .shipment-status,
  .shipment-info {
      height: auto;
      margin-right: 0;
      margin-bottom: 20px;
  }
  
  .status-detail h4.completed {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .shipment-header {
      height: auto;
      margin-top: 8px;
  }

  .shipment-id .shipment-header h3 {
      font-size: 1.8rem;
  }

  .shipment-id span,
  .shipment-id span.shipment-route {
      font-size: 24px;
  }

  .shipment-status h3,
  .shipment-info h3 {
      font-size: 1.2rem;
  }

  .shipment-info p {
      font-size: 1rem;
  }

  .shipment-info button {
      font-size: 14px;
  }

  .progress-container {
      height: 40px;
  }

  .shipping-icon {
      font-size: 1.5rem !important;
  }

  .status-icon {
      font-size: 2rem !important;
  }
}

@media (max-width: 480px) {
  .result-container {
      padding: 10px;
  }

  .shipment-header {
    gap: 12px;
  }

  .status-icon-container {
    top: -10px;
  }

  .shipment-id .shipment-header h3 {
      font-size: 1.5rem;
  }

  .shipment-id span,
  .shipment-id span.shipment-route {
      font-size: 20px;
  }

  .shipment-id span.shipment-route .icon {
      font-size: 1.2rem;
  }

  .shipment-status,
  .shipment-info {
      padding: 10px;
  }

  .shipment-status h3,
  .shipment-info h3 {
      font-size: 1rem;
  }

  .shipment-info p {
      font-size: 0.9rem;
  }

  .shipment-info button {
      font-size: 12px;
  }

  .progress-container {
      height: 30px;
  }

  .shipping-icon {
      font-size: 1rem !important;
  }

  .status-icon {
      font-size: 1.5rem !important;
  }

  .status-detail li {
    display: none;
  }
}