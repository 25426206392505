.blogHeader {
  position: relative;
  height: 350px;
  width: 100%;
  background-color: #042B3B;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
}

.blogHeaderContent {
  max-width: 1200px;
  width: 100%;
}

.blogHeader h1 {
  font-size: 64px;
  color: #E6AF4B;
}

.blogHeader h3 {
  font-size: 28px;
  color: #f7f7f7;
}


.blogContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}


.blogContent {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;
}

.blogCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(33.333% - 1rem);
  overflow: hidden;
  margin-bottom: 1rem;
}


.blogCard img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.blogCard:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.blogCard img:hover {
  border-radius: 10px 10px 0 0;
}


.blogCard-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.meta-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.blogCard-info h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 400;
  color: #666;
}

.blogCard-info p {
  color: #666;
  font-size: 1rem;
  margin-bottom: 10px;
}

.blogCard-info .date {
  color: #999;
  font-size: 0.9rem;
  margin-top: auto;
}

.blogCard-info .reading-time {
  color: #999;
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  .blogCard {
    width: calc(50% - 1rem);
  }
}

@media screen and (max-width: 768px) {
  .blogCard {
    width: 100%;
  }

  .blogContents h1 {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {
  .blogHeader {
    height: 250px;
  }

  .blogHeader h1 {
    font-size: 36px;
  }

  .blogContents h1 {
    font-size: 24px;
  }
}
