.sliderBrandHeader {
  text-align: right;
  margin-right: 6rem;
  background-color: #f4f6f8;
}

.sliderBrandHeader h1 {
  color: hsl(0, 0%, 30%);
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  margin-bottom: 12px;
  font-style: italic;
  text-decoration: underline;
}

.sliderBrand {
    height: 100%;
    position: relative;
    margin: auto;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
    background-color: #f4f6f8;
  }


  .sliderBrand::before,
.sliderBrand::after {
    background: linear-gradient(to right, rgb(244,246,248)   10%,
    rgba(255,255,255,0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;

}

.sliderBrand::before {
    top: 0;
    left: 0;
}

.sliderBrand::after {
    top: 0;
    right: 0;
    transform: rotateZ(180deg);
}
  
  .slideBrand-track {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    animation: cambio 40s infinite linear;
  }
  
  @keyframes cambio {
    from {margin-left: 0%}
    to {margin-left: -100%}
  }
  
  .slideBrand {
    height: 50px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6rem;
  }
  
  .slideBrand img {
    min-width: 100%;
    height: 100%;
    transition: transform 0.5s;
    object-fit: cover;
  }

  
  @media only screen and (max-width: 480px) {

    .sliderBrandHeader  {
      margin-right: 1rem;
    }
    
    .sliderBrandHeader h1 {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
    }
  
    .slideBrand-track {
      height: 100px;
    }
  
    .slideBrand {
      height: 75px;
      width: 75px;
      margin-left: 1rem;
    }
  
    .slideBrand img {
      width: 50%;
      height: 50%;
    }
  }
  