.ShipmentTracking {
    text-align: center;
}

.loading-container {
  height: 50px;
  color: #042B3B; /* Change text color to match your theme */
}

.searcherror {
  height: 500px;
  background-image: url('../../images/404cover.png'); /* Replace with your image path */
  background-size: contain;
  background-position: center;
    /* background-repeat: no-repeat; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center; /* Center text horizontally */
  padding: 20px; /* Add some padding for better text spacing */
  color: #042B3B; /* Change text color to match your theme */
}

.searcherror-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  height: auto;
  max-width: 50%;
}

.searcherror h1 {
  font-size: 44px;
  font-weight: 500;
  margin-bottom: 0;
  color: #042B3B;
}
.searcherror p {
  font-size: 20px;
  font-weight: 300;
  margin: 10px 0;
  width: 70%;
  color: #333;
}

.error-btn {
  margin: 24px 0;
}

.error-btn a {
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #042B3B;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/*  Contact Container Start */

.contact-container {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: #042B3B;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;
  margin: 40px auto;
  text-align: left;
}

  
  .contact-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 60%;
    z-index: 2;
  }

  .contact-image {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
  
  .contact-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .contact-container h1 {
    font-size: 42px;
    padding: 12px 0;
  }
  .contact-container p {
    font-size: 18px;
    line-height: 1.5;
    padding: 12px 0;
  }

  .contact-link {
    color: #042B3B;
    text-decoration: underline;
    transition: color 0.3s;
  }
  
  .contact-link:hover {
    color: #063A4F;
    font-weight: 500;
  }
  

  /* Responsive Design */

@media (max-width: 1024px) {
  .searcherror-text {
      max-width: 70%;
  }

  .contact-text {
      width: 60%;
  }
}

@media (max-width: 768px) {
  .searcherror {
      padding: 10px;
      height: auto;
  }

  .searcherror-text {
      max-width: 90%;
  }

  .searcherror h1 {
      font-size: 36px;
  }

  .searcherror p {
      font-size: 16px;
      width: 90%;
  }

  .contact-container {
      height: auto;
      flex-direction: column-reverse;
      padding: 10px;
  }

  .contact-text {
      width: 80%;
  }

  .contact-container h1 {
      font-size: 32px;
  }

  .contact-container p {
      font-size: 16px;
  }

}

@media (max-width: 480px) {
  .searcherror h1 {
      font-size: 28px;
  }

  .searcherror p {
      font-size: 14px;
      width: 100%;
  }

  .error-btn a {
      padding: 6px 12px;
  }

  .contact-text {
      width: 100%;
  }

  .contact-container h1 {
      font-size: 24px;
  }

  .contact-container p {
      font-size: 14px;
  }
  .contact-image {
    object-fit: contain;
    width: 100%;
  }
}