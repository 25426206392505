.inlandHeader {
    position: relative;
    height: 350px;
    width: 100%;
    background-color: #042B3B;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
}

.inlandHeaderText {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inlandHeaderText h1 {
    font-size: 64px;
    color: #E6AF4B;
    margin-bottom: 1rem;
}

.inlandHeaderText h3 {
    font-size: 28px;
    color: #ffffff;
    width: 70%;
}

.inlandContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.inlandContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.inlandContent-left,
.inlandContent-right {
    width: 50%;
    padding: 1rem;
    box-sizing: border-box;
}

.inlandContent-left h1 {
    color: #042B3B;
    font-size: 32px;
    margin-bottom: 1rem;
}

.inlandContent-left p {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
    padding: 12px 0;
}

.inlandContent-right img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.inlandContent2 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 2rem;
}

.inlandContent2 h1 {
    color: #042B3B;
    font-size: 32px;
    margin-bottom: 1rem;
    text-align: center;
}

.inlandContent2 h3 {
    color: #042B3B;
    font-size: 28px;
    margin-bottom: 1rem;
    text-align: left;
}

.inlandContent2 p {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 2rem;
    text-align: left;
}

.inlandContent2-detail {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    width: 100%;
}

.inlandContent2-detail ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    width: 50%;
}

.inlandContent2-detail ul li {
    font-size: 18px;
    color: #333;
    line-height: 1.6;
    padding: 0.5rem 0;
    margin-top: 1rem;
    border-bottom: 1px solid #ddd;
}

.inlandContent2-detail ul li:last-child {
    border-bottom: none;
}

.inlandContent2-detail span {
    color: #042B3B;
    font-size: 32px;
    font-weight: 500;
}

.inlandContent2-detail img {
    width: 50%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1024px) {
    .inlandHeaderText h1 {
        font-size: 48px;
    }

    .inlandHeaderText h3 {
        font-size: 24px;
    }

    .inlandContent-left h1 {
        font-size: 28px;
    }

    .inlandContent2 h1,
    .inlandContent2 h3 {
        font-size: 24px;
    }

    .inlandContent2-detail ul {
        width: 100%;
    }

    .inlandContent2-detail img {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .inlandContent {
        flex-direction: column;
    }

    .inlandContent-left,
    .inlandContent-right {
        width: 100%;
    }

    .inlandContent2 {
        padding: 1rem;
    }

    .inlandContent2 h1 {
        font-size: 28px;
    }

    .inlandContent2 h3 {
        font-size: 24px;
    }

    .inlandContent2-detail {
        flex-direction: column;
    }

    .inlandContent2-detail ul,
    .inlandContent2-detail img {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .inlandHeaderText h1 {
        font-size: 36px;
    }

    .inlandHeaderText h3 {
        font-size: 18px;
    }

    .inlandContent-left h1 {
        font-size: 24px;
    }

    .inlandContent2 h1,
    .inlandContent2 h3 {
        font-size: 20px;
    }

    .inlandContent2-detail ul li {
        font-size: 16px;
    }
}
