.contactContainer {
    width: 100%;
    height: 450px;
    display: flex;
    position: relative;
    background: #000000;
}

.contactContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
}

.contactText {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.contactText h1 {
    width: 55%;
    margin-top: 12px;
    color: #E6AF4B;
    font-size: 36px !important;
    text-shadow: 4px #1818188a;
    text-align: center;
}


.contactButton {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.contactBtn {
    margin-top: 2rem !important;
    color: #E6AF4B !important;
    font-size: 18px !important;
    background-color: transparent !important;
    border: 1px solid #E6AF4B !important; 
    transition: background-color 0.3s, color 0.3s !important;
}

.contactBtn:hover {
    background-color: #E6AF4B !important;
}

.contactBtn .home-icon {
    color: #E6AF4B;
}
.contactBtn:hover .home-icon {
    color: white;
}


@media only screen and (max-width: 768px) {
    .contactText h1 {
        width: 80%;
    }
}



@media only screen and (max-width: 480px) {
    .contactText h1 {
        font-size: 24px !important;
        width: 90%;
    }

    .contactBtn {
        font-size: 16px !important
    }
};
    
