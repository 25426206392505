.contactFormHeader {
  position: relative;
  height: 350px;
  width: 100%;
  background-color: #042B3B;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
}

.contactFormHeaderContent {
  max-width: 1200px;
  width: 100%;
}

.contactFormHeaderContent h1 {
  font-size: 64px;
  color: #E6AF4B;
  margin-bottom: 1rem;
}

.contactFormHeaderContent h3 {
  font-size: 28px;
  color: #ffffff;
}

.contactForm1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-sizing: border-box;
}

.contactForm1Left,
.contactForm1Right {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
}

.contactForm1Left h1 {
  text-align: start;
  color: #042B3B;
  width: 100%;
  padding: 12px 0;
  font-size: 32px;
}

.contactForm1Left p {
  text-align: start;
  width: 100%;
  line-height: 1.8;
  color: #333333;
  font-size: 18px;
}


.contactForm2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  box-sizing: border-box;
}

.contactForm2Top {
  width: 100%;
  padding: 12px;
  text-align: center;
}


.contactForm2Table {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 12px;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 10px;
  padding: 1rem;
  box-sizing: border-box;
}

.contactTable {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23);
  transition: background 0.3s ease, transform 0.3s ease;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}

.contactTable:hover {
  transform: scale(1.05);
}


.customContactIcon {
  position: absolute;
  bottom: -60px;
  right: -75px;
  width: 100%;
  height: 100%;
  color: #2b61a442;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}


.contactForm2Detail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 600;
  padding: 12px;
  width: 100%;
  height: 100%;
  gap: 12px;
  z-index: 2; /* Make sure this is higher than the icon's z-index */
  position: relative;
}



.contactForm2DetailHeader h3 {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  color: #042B3B;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.contactForm2Detail-2 {
  width: 100%;
}

.contactForm2Detail-2 p {
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  color: #042B3B;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  cursor: pointer;
}

.contactForm2Detail a {
  color: #042B3B;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  word-wrap: break-word;
}

.contactForm2Detail a:hover {
  color: #042B3B;
}

.contactForm2Detail a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #042B3B;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.contactForm2Detail a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.contactForm2Btm {
  width: 100%;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 80%;
  width: 100%;
  position: absolute;
}

.contactFaq {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 786px) {
  .contactForm2Table {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  .contactForm1Left h1 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .contactForm1 {
    flex-direction: column;
  }

  .contactFormHeader h1 {
    font-size: 36px;
  }

  .contactForm1Left {
    width: 100%;
    margin-top: 0;
  }

  .contactForm1Left h1 {
    width: 100%;
  }

  .contactForm1Left p {
    font-size: 16px;
    width: 100%;
  }

  .contactForm1Right {
    width: 100%;
  }

  .contactForm2 {
    height: 100%;
  }

  .contactTable {
    height: 200px;
  }

  .contactForm2DetailHeader h3 {
    font-size: 16px;
  }


  .contactForm2Detail-2 p {
    font-size: 10px;
  }

  
}
