.compareFreight {
    width: 100%;
    height: 100%;
    background-color: #f4f6f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.compareFreight h1 {
    font-weight: 600;
    color: #042B3B;
    margin-top: 4rem;
}

.freight-container {
    max-width: 1024px;
    width: 80%;
    height: 550px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    border-radius: 15px;
    margin-top: 1rem;
    background-color: #fff; /* Add a background color */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a box shadow */
  }


  .freightItemTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #042B3B; /* Add a background color */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: .2px dotted #042B3B;
    border-radius: 15px 15px 0 0;
  }
  .freightItemTitle h1 {
    font-size: 24px;
    font-weight: 600;
    color: #f4f6f8;
    margin: 12px;
  }
  .freightItemTitle img {
    height: 120px;
    width: 120px;
    object-fit: contain;
  }


  .freightItemQ {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    border: .2px dotted #042B3B;
  }
  .freightItem {
    display: flex;
    justify-content: center;
    align-items: center;
    border: .2px dotted #042B3B;
  }
  .freightItem img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }


  .compareFreightBtn button {
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 12px 24px;
    background-color: #E6AF4B;
    box-shadow: 0px 0px 5px #e6b04b95;
    color: #333333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 22px;
    gap: 12px;
    margin-top: 22px;
}

.compareFreightBtn button:hover {
    transform: scale(1.05);
    background-image: linear-gradient(to bottom, #E6AF4B, #F9D49C);    
    transition: 0.5s ease;
    box-shadow: 0px 0px 5px #F9D49C;
}
  
  @media only screen and (max-width: 768px) {
    
    .freightItemTitle {
      font-size: 20px;
    }
    
    .freightItemQ {
      font-size: 16px;
    }
    
    .freightItem img {
      width: 24px;
      height: 24px;
    }
  }


  @media only screen and (max-width: 480px) {

    .freight-container {
      height: 550px;
    }

    .freightItemTitle img {
      width: 60px;
      height: 60px;
    }

    .compareFreight h1 {
      font-size: 16px;
    }

    .compareFreightBtn button {
      padding: 6px 12px;
      margin-left: 0;
      gap: 0;
      font-size: 14px;
  }
  }