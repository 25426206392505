.form {
    background: #e6b04bc5;
    box-shadow: 0 0 10px 2px rgba(93, 60, 0, 0.6);
    width: 450px;
    height: 400px;
    border-radius: 10px;
    display: flex;
    text-shadow: none;
    padding: 12px;

  }


  
  .formFields {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .formField {
    width:  90%;
    margin-left: 12px;
    opacity: 1 !important;
  }
  
  
  .formField input,
  .formField select {
    display: block;
  margin: 0 auto;
    box-sizing: border-box;
    background: #fcdca4;
    color: #575757c9;
    padding: 8px;
    width: 100%;
    border-radius: 5px;
    -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  }
  
  .formField option {
    margin-top: 6px;
    background: #e6e6e6;
    color: #575757c9;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }



  .form-control {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #ececec;
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    margin-bottom: 20px;
    color: #333;
    transition: all 0.5s ease-in-out;
  }
  
  .form-control:focus {
    border-bottom: 2px solid #042B3B;
    outline: none;
  }
  
  .form-control:focus ~ .label, .form-control:valid ~ .label {
    top: -30px;
    font-size: 14px;
    color: #042B3B;
  }
  

  
  .formInputBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
  }
  
  .formInputBtn button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 95%;
    transition: all 0.3s ease;
    margin-bottom: 0 !important;
  }
  
  .formInputBtn button:hover {
    background-color: #063A4F !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  .formInputBtn button svg {
    margin-left: 5px;
  }

  @media screen and (max-width: 480px) {
    .form {
        width: 300px;
        height: 300px;
    }
    .formField {
      width: 85%;
    }
  }