.companylogo {
    margin-top: 6px;
}

.navBar  {
    width: 100%;
    height: 64px;
    position: fixed;
    transition: .3s ease-in;
    overflow: hidden;
    z-index: 999;
}

/* Navbar button styling */
.navBtn {
    background-color: #042B3B !important;
    color: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    text-transform: uppercase;
    font-weight: 500;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 6px;
  }
  
  .navBtn:hover {
    background-color: #063A4F !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .navBtn:active {
    background-color: #042B3B;
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }


.navBar.active {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navTool {
    height: 64px;
}



.navDropdownItem {
    color: rgba(0, 0, 0, 0.6) !important;
}
