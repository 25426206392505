.airHeader {
    position: relative;
    height: 350px;
    width: 100%;
    background-color: #042B3B;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
  }


.airHeaderText {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

 
.airHeaderText h1 {
    color: #E6AF4B; 
    font-size: 64px;
    margin-bottom: 1rem;
}


.airHeaderText h3 {
    width: 70%;
    font-size: 28px;
    color: white;
}




@media only screen and (max-width: 480px) {
    .airHeaderText h1 {
        width: 80%;
        font-size: 36px;
    }

    .airHeaderText h3 {
        font-size: 18px;
        width: 80%;
    }
}