.sidebarHeader {
    display: flex !important;
    position: sticky !important;
    margin-top: 2rem !important;
}

.sidebarHeader h1 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
}
.sidebar {
    max-height: 750px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: sticky;
    flex-wrap: nowrap;
    overflow-y: scroll;
    scroll-behavior: smooth;
    white-space: wrap;
    scrollbar-width: none;
    top: 80px;
    gap: 20px;
    padding: 0 10px;
}

::-webkit-scrollbar {
    width: 6px;
  }

::-webkit-scrollbar-track {
    background: #ffffff;
  }

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #e2e2e2;
  }



.sidebarPost {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    height: 350px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
}
.sidebarPost img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}


.sidebarPostBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sidebarPostBtn button {
    border: none;
    padding: 10px 20px;
    background-color: #fff;
    color: #E6AF4B;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 0 0 10px 10px;

}

.sidebarPostBtn button:hover {
    background-color: #E6AF4B;
    color: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.339);
}

.sidebarPostAll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 8px;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #e2e2e2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.sidebarPostAll:hover {
    border: 1px solid #E6AF4B;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebarPostAll h2 {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: #E6AF4B;
}

.sidebarPostAllBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
}

.sidebarPostAllBtn button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #E6AF4B;
    font-size: 18px;
    transition: color 0.3s ease;
}

.sidebarPostAllBtn button:hover {
    color: #b5872b;
}



@media screen and (max-width: 768px) {
    .sidebarHeader {
        margin-top: 0;
    }
   .sidebar {
    max-height: 450px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        gap: 1rem;
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-behavior: smooth;
        white-space: wrap;
        scrollbar-width: none;
        top: 0;
        padding: 0;
   } 

   .sidebarPost {
    position: relative;
    min-width: 250px;
    width: 100%;
    height: 100%;
}
.sidebarPost img {
    height: 50%;
}

    .sidebarPostAll{
        position: relative;
        justify-content: center;
        align-items: center;
    min-width: 250px;
    height: 150px;
    padding: 0;
    }


}