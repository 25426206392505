/* CbmCalculator.css */

.cbm-calculator {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: transparent;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    border-radius: 5px;
    text-align: center;
  }

  .cbmTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
  }
  
  .cbmTitle h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #042B3B;
  }
  
  .cbmTitle p {
    width: 80%;
    font-size: 16px;
    font-weight: 400;
    color: #042B3B;
  }

  .input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-bottom: 12px;
  }

  .input-spacing {
    text-align: left;
    padding: 8px;
  }
  
  .input-spacing label {
    font-size: 16px;
    color: #333;
  }
  
  .input-spacing input
 {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 0 8px;
    border: none;
    border-radius: 3px;
    background-color: #e6e6e6;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: #333;
  }

  .input-spacing select
  {
     width: 100%;
     height: 40px;
     font-size: 14px;
     padding: 0 8px;
     border: none;
     border-radius: 3px;
     background-color: #e6e6e6;
     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
     color: #333;
   }
  
  .calBtn {
    width: 100%;
    padding: 10px;
    background-color: #042B3B;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .results {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .results h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #042B3B;
  }
  
  .results p {
    font-size: 16px;
    margin: 5px 0;
    color: #333;
  }
  
  /* Style totalCBM, totalWeight, and volumeWeight */
  .results .result-label {
    font-weight: bold;
    color: #042B3B;
  }
  
  .results .result-value {
    font-weight: bold;
    color: #333;
  }
  
  /* Style the email link */
  .contactEmail {
    text-decoration: underline;
    color: #042B3B;
  }
  
  .contactEmail:hover {
    color: #333;
  }
  

  @media only screen and (max-width: 480px) {
    .cbm-calculator {
        margin-top: 5rem;
        max-width: 100%;
        padding: 0;
      }
    
      .input-container {
        grid-template-columns: 1fr; 
      }
    
      .calBtn {
        font-size: 14px;
        width: 90%;
      }

      .input-spacing input {
        padding: 0;
      }
  }