.carousel-wrapper {
    width: 500px;
    height: 500px;
    margin: 0 auto;
  }

  .slick-dots {
    display: block !important;
    width: 100px;
    height: 100px;
  }

  .carousel-arrow {
    color: #333333;
    border-radius: 50%;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  
  .carousel-arrow:hover {
    background-color: #c0c0c0;
    cursor: pointer;
  }


  @media only screen and (max-width: 480px) {
    .carousel-wrapper {
        width: 350px;
        height: 350px;
    }
  }
