.b2b-container {
  background-color: #f4f6f8;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vh;
  margin: 0 auto;
  padding: 2rem;
  margin-top: 6rem;
  height: auto;
  width: 100%;
  flex-wrap: wrap; /* Added to allow wrapping on smaller screens */
}

.b2b-header {
  margin-bottom: 3rem;
  text-align: center;
  position: relative;
}

.b2b-box {
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
}

.b2b-box h1 {
  color: #042B3B;
  font-size: 36px;
  padding: 12px;
  font-weight: bold;
  text-align: center;
  position: relative;
  text-transform: uppercase;
}

.b2b-box h1::after {
  content: '';
  display: block;
  width: 40%;
  height: 5px;
  background-color: #E6AF4B;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  border-radius: 5px;
}

.b2b-textbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
  flex-wrap: wrap;
}

.b2b-textbox h5 {
  font-weight: 300;
  font-size: 18px;
  color: #042B3B;
  width: 48%;
  line-height: 1.5;
  text-align: center;
}

.english {
  text-align: center;
}

.thai {
  text-align: center;
  width: 50%;
}

.b2b-cards {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.b2b-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;
  width: 250px;
  height: 350px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 0 4px 18px rgba(0, 0, 0, 0.8);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  color: #fff;
  overflow: hidden;
}

.b2b-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(103, 103, 103, 0.264); /* Black overlay with 50% opacity */
  z-index: 1;
  transition: background 1s;
}

.b2b-card h2,
.b2b-card h4,
.b2b-card ul,
.b2b-card button {
  position: relative;
  z-index: 2;
}

.b2b-card:nth-child(1) {
  background: url('../../images/b2b-1-1.png') center/cover no-repeat;
}

.b2b-card:nth-child(2) {
  background: url('../../images/b2b-2-2.png') center/cover no-repeat;
}

.b2b-card:nth-child(3) {
  background: url('../../images/b2b-3-3.png') center/cover no-repeat;
}

.b2b-card:nth-child(4) {
  background: url('../../images/b2b-4-4.png') center/cover no-repeat;
}

.b2b-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: rgba(4, 43, 59, 0.863); /* Black overlay with 50% opacity */
}

.b2b-card h2 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 10px;
}

.b2b-card:hover h2 {
  color: #ececec;
}

.b2b-card h4 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.b2b-card p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
}

.b2b-card ul {
  /* list-style-type: none; */
  padding: 0;
  margin: 0;
}

.b2b-card ul li {
  display: none;
  text-align: center;
  line-height: 1.5;
}

.b2b-card:hover ul li {
  display: block;
}

.b2b-card button {
  display: none;
  margin-top: 2rem;
  padding: 5px 10px;
  background-color: transparent;
  color: white;
  border: 1px solid #fff !important;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.b2b-card:hover button {
  display: block;
}

.b2b-card button:hover {
  background-color: #042B3b;
  color: white;
  border: 1px solid #042B3b !important;
}

@media only screen and (max-width: 1200px) {
  .b2b-card {
      width: 45%;
  }
}

@media only screen and (max-width: 768px) {
  .b2b-header h1 {
      font-size: 36px;
  }

  .b2b-header p {
      font-size: 16px;
  }

  .b2b-cards {
    flex-wrap: wrap;
  }

  .b2b-card {
      width: 100%;
      margin-top: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .b2b-container {
      flex-direction: column;
      padding: 0;
  }

  .b2b-header h1 {
      font-size: 28px;
  }

  .b2b-header p {
      font-size: 14px;
  }

  .b2b-textbox {
      flex-direction: column;
      align-items: center;
  }

  .b2b-textbox h5 {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
  }

  .b2b-box h1::after {
    width: 100%;
  }

  .b2b-card {
      width: 100%;
      height: auto;
      margin-top: 12px;
      padding: 1rem;
  }

  .b2b-card h2 {
      font-size: 20px;
  }

  .b2b-card h4 {
      font-size: 16px;
  }

  .b2b-card p {
      font-size: 14px;
  }

  .b2b-card button {
      padding: 8px 16px;
      font-size: 14px;
  }
}
