.ShipmentTracking-header {
    background-image: url('../../images/trackcover.png');
  background-size: cover;
  background-position: center;
    background-color: #042B3B;
    min-height: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.366);
}

.ShipmentTracking-header h1{
    color: #E6AF4B;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.74);
    margin-bottom: 0;
}

.ShipmentTracking-header p {
    width: 25%;
    color: white;
    font-size: 18px;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.832);
    margin: 12px 0;
}

.ShipmentTracking-header h5 {
  color: #E6AF4B;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 20px auto;
    padding: 20px;
    height: auto;
    width: 500px;
    border: 1px solid #042B3B;
    border-radius: 10px;
    background-color: #f4f4f4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
}

.input-wrapper {
    position: relative;
    display: inline-block;
    width: 90%;
  }
  
  
  .clear-button {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 32px;
    padding: 0;
    margin: 0;
  }
  
  .clear-button:hover {
    color: #000;
    background-color: transparent;
  }

.search-container input {
    width: 80%;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #ccc;
    flex-grow: 1;
    margin-right: 10px;
    background-color: #f4f4f4;
        transition: border-bottom 0.3s, background-color 0.3s, box-shadow 0.3s;
}

.search-container input:focus {
    border-bottom: 2px solid #042B3B; /* Highlight border color */
    background-color: #fff; /* Change background color on focus */
    outline: none; /* Remove default focus outline */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    border-radius: 5px;
  }

.search-container .searchBtn {
    width: 75px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #042B3B;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }

.search-container .searchBtn:hover {
    background-color: #031E28;
    animation: bounce infinite 1.8s;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.search-container .searchBtn:active {
    background-color: #042B3B;
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.error {
    color: red;
    margin: 20px;
}

/* Responsive Design */

@media (max-width: 1024px) {
  .ShipmentTracking-header {
      font-size: calc(8px + 2vmin);
  }

  .ShipmentTracking-header p {
      width: 40%;
  }

  .search-container {
      width: 80%;
  }
}

@media (max-width: 768px) {
  .ShipmentTracking-header {
      font-size: calc(6px + 2vmin);
      min-height: 300px;
  }

  .ShipmentTracking-header p {
      width: 60%;
      font-size: 16px;
  }

  .search-container {
      padding: 15px;
  }

  .search-container input {
      width: 70%;
      font-size: 14px;
  }

  .search-container .searchBtn {
      padding: 8px 16px;
      font-size: 14px;
  }
}

@media (max-width: 480px) {
  .ShipmentTracking-header {
      font-size: calc(4px + 2vmin);
  }

  .ShipmentTracking-header h1 {
      font-size: 24px;
  }

  .ShipmentTracking-header p {
      width: 80%;
      font-size: 14px;
  }

  .search-container {
      padding: 10px;
  }

  .search-container input {
      width: 80%;
      font-size: 12px;
  }

  .search-container .searchBtn {
      padding: 6px 12px;
      font-size: 12px;
  }

  .clear-button {
      font-size: 24px;
  }
}