.sec4 {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: row-reverse;
}
.sec4Left {
    flex: 1;
    width: 100%;
    height: 100%;
}

.sec4Left img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.sec4Right {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 12px;
}

.sec4Right h1 {
    width: 80%;
    font-size: 32px;
    color: #042B3B;
    font-weight: bold;
    text-shadow: 1px 1px #00000079;
    position: relative;

}

.sec4Right h1::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 5px;
    border-radius: 10px;
    background-color: #E6AF4B;
    left: 0;
    top: 3rem;
}

.sec4Right p {
    color: #042B3B;    
    font-size: 20px;
    width: 80%;
    font-weight: 400;
    margin-bottom: 12px;
    padding: 12px 0;
    line-height: 1.5;
}

.sec4Btn {
    width: 80%;
}

.sec4Btn button {
    padding: 5px 20px 7px;
    border-radius:  0.25rem;
    border: 2px solid transparent;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    color: white;
    background-color: #042B3B;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.538);
}

.sec4Btn button:hover {
    background-color: #063A4F !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 768px) {
    .sec4 {
        margin-top: 2rem;
        height: 100%;
        flex-direction: column-reverse;
    }
    .sec4Right {
        align-items: center;
        text-align: center;
        padding: 0;
        height: 100%;

    }   
}


@media only screen and (max-width: 480px) {


    .sec4Right h1{
        font-size: 24px;
    }

    .sec4Right h1::before {
        width: 100%;
    }

    .sec4Right p {
        font-size: 16px;
        width: 90%;
    }

    
}