.about {
    background-color: rgb(244, 246, 248);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.aboutHeader {
    height: 100vh;
    max-width: 100%;
    display: flex;
    position: relative;
    background: #000000;
}



.aboutHeaderText {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-size: 52px;
    color: #ffffff;
    text-shadow: 2px 2px #00000073;
    z-index: 2;
}



.aboutHeaderTextHighlight {
    color: #E6AF4B;
}


.aboutHeaderText p {
    text-align: center;
}


.aboutSection {
    height: 550px;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    text-align: center;
}
.aboutSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1; /* set a lower z-index */
    clip-path: circle(40% at 100% 0%);
    background-color: #e6af4b;
  }


.aboutSection h1 {
    max-width: 80%;
    font-size: 52px;
    color: #042B3B;
    z-index: 10;
}

.aboutSection h3 {
    max-width: 80%;
    font-size: 36px;
    color: #042B3B;
    z-index: 10;
}

.aboutSection p {
    margin-top: 2rem;
    max-width: 80%;
    font-size: 18px;
    line-height: 2rem;
    z-index: 10;
}

.aboutSection2 {
    display: flex !important;
    flex-direction: row;
    height: 100%;
}


.aboutLeft {
    width: 50%;
    height: 450px;
}

.aboutLeft img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.aboutRight {
    z-index: 10;
    width: 50%;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
}


.aboutRight h1 {
    width: 80%;
    color: #042B3B;
}

.aboutRight p {
    width: 80%;
    padding: 20px 0;
    font-size: 18px;
    line-height: 2rem;

}



.aboutSection3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2rem;
    width: 100%;
    height: 450px;
    background: #042B3B;
    color: #ffffff;

}


.aboutSection3 h1 {
    width: 50%;
    font-size: 52px;
    font-weight: bold;
    padding: 16px 0;
}

.aboutSection3 p {
    width: 50%;
    font-size: 26px;
    font-weight: 400;
}


.aboutSection4 {
    margin: 2rem;
    display: flex !important;
    flex-direction: row-reverse;
    max-height: 700px;
}

.aboutLeft4 {
    width: 50%;
    height: 600px;

}

.aboutLeft4 img {
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.aboutRight4 {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-left: 12px;

}

.aboutRight4 h1 {
    width: 80%;
    color: #042B3B;
}

.aboutRight4 p {
    width: 80%;
    padding: 20px 0;
    font-size: 18px;
    line-height: 2rem;
    
}






@media only screen and (max-width: 768px) {
    .aboutRight4 h1 {
        font-size: 24px;
    }
    
    .aboutSection h1 {
        font-size: 36px;
    }

    .aboutSection2 {
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .aboutLeft {
        width: 100%;
    }

    .aboutLeft img {
        height: 100%;
    }
    .aboutRight {
        width: 100%;
        height: 100%;
        margin-left: 0;

    }

    .aboutSection4 {
        flex-direction: column;
        height: 100%;
    }
    .aboutLeft4 {
        width: 100%;
    }
    .aboutLeft4 img {
        height: 100%;
    }
    .aboutRight4 {
        width: 100%;
        margin-left: 0;
    }


}


@media only screen and (max-width: 480px) {

    .aboutHeader {
        height: 100vh;
    }


    .aboutHeaderText {
        font-size: 24px;
    }

    .aboutSection {
        height: 100%;
        padding: 0;
    }


    .aboutSection h1 {
        font-size: 24px;
        width: 100%;
    }

    .aboutSection h3 {
        font-size: 20px;
        width: 100%;
    }

    .aboutSection p {
        font-size: 16px;
        width: 100%;
    }

    .aboutSection2 {
        flex-direction: column;
        height: 100%;
    }

    .aboutLeft {
        width: 100%;
        height: 350px;
        
    }

    .aboutLeft img {
        height: 100%;
    }

    .aboutRight {
        width: 100%;
        height: 100%;
        padding: 2rem 0;
        margin-left: 0;

    }

    .aboutRight h1 {
        font-size: 24px;
    }

    .aboutSection4 {
        flex-direction: column;
        height: 100%;
    }

    .aboutLeft4 {
        width: 100%;
        height: 350px;
    }

    .aboutLeft4 img {
        height: 100%;
    }

    .aboutRight4 {
        width: 100%;
        height: 100%;
        padding: 2rem 0;
        margin-left: 0;

    }


    .aboutSection3 h1{
        font-size: 24px;
        width: 90%;
    }

    .aboutSection3 p {
        font-size: 18px;
        width: 90%;
    }

    .aboutSection4 {
        max-height: 100%;
    }

}