

.slider {
    height: 250px;
    position: relative;
    justify-content: center;
    margin: auto;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
    align-items: start;

}

.slide-track {
    display: flex;
    width: calc(350px * 18);
    animation: scroll 60s linear infinite;
}

/* .slide-track:hover {
    animation-play-state: paused;
} */


@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 9));
    }
}


.slide {
    height: 200px;
    width: 350px;
    display: flex;
    align-items: center;
    margin: 0 10px;
    overflow: hidden;
    perspective: 200px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

}

.slide img {
    width: 350px;
    height: auto;
    transition: transform .5s ease-in-out;

}

.slide img:hover {
    transform: scale(1.05);
}


.slider::before,
.slider::after {
    background: linear-gradient(to right, rgba(255,255,255,0.4), transparent);
    content: '';
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;

}

.slider::before {
    left: 0;
}

.slider::after {
    right: 0;
    background: linear-gradient(to left, rgba(255,255,255,0.4), transparent);}


@media only screen and (max-width: 480px) {


    .slide-track {
        width: calc(250px * 18);
        animation: scroll 40s linear infinite;

    }

    .slide {
        height: 200px;
        width: 400px;
    }

    .slide img {
        width: 250px;
        height: 100%;
    }


}

