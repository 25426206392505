.sec5 {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: row-reverse;
}
.sec5Left {
    flex: 1;
    width: 100%;
    height: 100%;
}

.sec5Left img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.sec5Right {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
}

.sec5Right h1 {
    width: 80%;
    font-size: 32px;
    color: #042B3B;
    font-weight: bold;
    text-shadow: 1px 1px #042b3bc6;

}

.sec5Right p {
    color: #042B3B;    
    font-size: 20px;
    font-weight: 500;
    width: 80%;
    font-weight: 500;
    padding: 12px 0;
    line-height: 1.5;
    margin-bottom: 12px;
}

.sec5Btn {
    width: 80%;
}

.sec5Btn button {
    padding: 6px 12px;
    background-color: #E6AF4B;
    box-shadow: 0px 0px 5px #e6b04b95;
    color: #333333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sec5Btn button:hover {
    transform: scale(1.05);
    background-image: linear-gradient(to bottom, #E6AF4B, #F9D49C);    transition: 0.5s ease;
    box-shadow: 0px 0px 5px #F9D49C;
}

@media only screen and (max-width: 768px) {
    .sec5 {
        margin-top: 2rem;
        height: 100%;
        flex-direction: column-reverse;
    }
    .sec5Right {
        align-items: center;
        text-align: center;
        padding: 0;
        height: 100%;

    }
}


@media only screen and (max-width: 480px) {
    .sec5Right {
        height: 100%;
    }

    .sec5Right h1{
        font-size: 24px;
    }


    .sec5Right p {
        font-size: 16px;
        width: 90%;
    }
    
}