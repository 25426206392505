.easblog {
    display: flex;
    flex-direction: row;
    gap: 50px;
    max-width: 1024px;
    margin: 0 auto;
    box-sizing: border-box;
}


.blogArticle {
    flex: 5;
    margin: 0 auto;
}

.blogArticleHeader {
    text-align: center;
}

.blogArticle {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.blogArticle h1 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 10px;
    color: #042B3B;
    font-weight: 500;
    width: 100%;
    padding: 12px;
  }
  
  .blogArticle h2 {
    font-size: 18px;
    text-align: center;
    font-style: italic;
    color: #042B3B;
    font-weight: 500;
    width: 95%;
    margin: 20px 0;
    line-height: 1.5;
  }
  
  .blogArticle img {
    display: block;
    margin: 20px auto;
    max-width: 100%;
  }
  
  .blogArticle section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  
  .blogArticle h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
    padding: 12px;
    color: #042B3B;
    font-size: 22px;
  }

  .blogArticle p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #042B3B;
    padding: 12px;
  }

  .blogArticle .highlight {
    font-weight: bold;
    color: red;
  }
  
  .blogArticle ul {
    text-align: left;
    margin: 10px 0px 20px 20px;
    font-size: 18px;
    color: #042B3B;
  }
  
  .blogArticle li {
    font-size: 16px;
    line-height: 1.5;
    margin: 10px 0;

  }

  .blogArticle h4 {
    color: #042B3B;
    font-weight: 500;
    font-size: 18px;
  }

  .blogArticle h5 {
    text-align: center;
    color: #8f8f8f;
    font-style: italic;
    font-weight: 500;
    text-decoration: underline;
  }

.sidebarComponent {
    flex: 2;
    padding: 16px;
  }


.blogtable {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.blogtable h1 {
  font-weight: 500;
  color: #042B3B;
  margin-top: 2rem;
  font-size: 24px;
}

.blogcontainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(8, .5fr);
  padding: 40px 0;
  margin-top: 1rem;
}






  @media screen and (max-width: 768px) {
    .easblog {
        flex-direction: column;
        gap: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .blogArticle {
        padding: 20px 0 !important;
      }
      .blogArticle section {
        margin-bottom: 0;
      }
      .blogArticle h1 {
        width: 100%;
        font-size: 24px;
        padding: 0;
      }
      .blogArticle h2 {
        width: 100%;
      }

      /* .blogArticle span {
        width: 80%;
      } */

      .blogtable h1 {
        font-size: 18px;
      }
  }