.seaHeader {
    position: relative;
    height: 350px;
    width: 100%;
    background-color: #042B3B;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
  }

.seaHeaderText {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

 
.seaHeaderText h1 {
    color: #E6AF4B; 
    font-size: 64px;
    margin-bottom: 1rem;
}

.seaHeaderText h3 {
    width: 70%;
    font-size: 28px;
    color: white;
}







.content1 {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    background-color: #f4f6f8;
   z-index: 0;
}
.content-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 50%;
    margin: 2rem 0;
}


.contentTitle {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    line-height: 1.5;
}


.contentTitle h1 {
    text-align: center;
    width: 100%;
    color: #042B3B; 
    font-size: 36px;
    font-weight: 600;  
}

.contentTitle p {
    width: 80%;
    color: #042B3B; 
    font-size: 24px;
    font-weight: 400;  
    border-left: #E6AF4B 5px solid;
    padding: 0 12px;
}


.content-1header h1{
    font-size: 36px;
    color: #042B3B; 
}

.content2 {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    background-color: #f4f6f8;
    z-index: 1;
}


.content-2 {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 1;
}

.content-2Left {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    line-height: 1.5;
    position: relative;
    z-index: 999;
    
}

.content-2Left h1{
    width: 80%;
    color: #042B3B; 
    font-size: 36px;
    font-weight: 600;  
}

.content-2Left h5{
    width: 80%;
    color: #E6AF4B; 
    font-size: 20px;
    font-weight: 600;  
}

.content-2Left p{
    width: 80%;
    color: #042B3B; 
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px; 
    line-height: 2rem;
}

.c2r-button button{
    padding: 6px 12px;
    background-color: #E6AF4B;
    box-shadow: 0px 0px 5px #e6b04b95;
    color: #333333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.c2r-button button:hover {
    transform: scale(1.05);
    background-image: linear-gradient(to bottom, #E6AF4B, #F9D49C);    transition: 0.5s ease;
    box-shadow: 0px 0px 5px #F9D49C;
}



.content-2Left-More {
    display: inline-block;
    margin-right: 10px;
}

.content-2Left-More span {
    font-size: 18px;
    font-weight: 600;
    color: #E6AF4B;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    display: inline-block;
}

.content-2Left-More span::before {
    content: ''; /* Create a pseudo-element for the underline */
    position: absolute;
    margin-top: 14px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px; /* Set the height of the underline */
    background-color: #E6AF4B; /* Define the underline color */
    transform: scaleX(0); /* Initially set the scale to 0 to hide the underline */
    transform-origin: right; /* Set the transform origin to the right */
    transition: transform 0.3s ease; /* Add a transition for the transform */
}

.content-2Left-More span:hover::before {
    transform: scaleX(1); /* Expand the underline to 100% on hover */
    transform-origin: left; /* Change the transform origin to the left for the effect */
}


.content-2Right {
    display: flex;
    width: 500px;
    height: 500px;
    position: relative;
    z-index: 999;

}

.content-2Right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}




.content3 {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    background-color: #f4f6f8; /* change background color to white */
    z-index: 1;
}

.content3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1; /* set a lower z-index */
    /* clip-path: circle(20% at 100% 100%);
    background-color: #e6af4b; */
  }

.content-3 {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 1;
}

.content-3Left {
    display: flex;
    width: 500px;
    height: 500px;
}


.content-3Left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-3Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;
    color: #042B3B;

}

.content-3Right h1 {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: bold;
    margin-left: 22px;
    }

    .content-3Right h5 {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 400;
        text-decoration: underline;
    }
    
    .content-3Right ul {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
    }
    
    .content-3Right li {
    font-size: 18px;
    margin-bottom: 10px;
    padding-left: 20px;
    text-indent: -10px;
    font-weight: 300;
    }

    .content-3Right p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
        margin-left: 22px;
        line-height: 1.5;
    }

    .c3r-button button {
        padding: 6px 12px;
        background-color: #E6AF4B;
        box-shadow: 0px 0px 5px #e6b04b95;
        color: #333333;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 22px;
    }
    
    .c3r-button button:hover {
        transform: scale(1.05);
        background-image: linear-gradient(to bottom, #E6AF4B, #F9D49C);    transition: 0.5s ease;
        box-shadow: 0px 0px 5px #F9D49C;
    }



.content4 {
    width: 100%;
    height: 90vh;
    display: flex;
    position: relative;
    background-color: #f4f6f8;
    z-index: 0;
}

.content4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1; /* set a lower z-index */
  }

.content-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    position: relative;
    z-index: 1;
}

.content-4Left {
    display: flex;
    width: 500px;
    height: 500px;
}


.content-4Left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-4Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;
    color: #042B3B;

}

.content-4Right h1 {
    width: 80%;
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: bold;
    margin-left: 22px;
    }

    .content-4Right p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
        padding-left: 20px;
        line-height: 1.5;
    }
    
    .content-4Right ul {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
    }
    
    .content-4Right li {
    font-size: 18px;
    margin-bottom: 10px;
    padding-left: 20px;
    text-indent: -10px;
    font-weight: 300;
    }

    
    .c4r-button button {
        padding: 6px 12px;
        background-color: #E6AF4B;
        box-shadow: 0px 0px 5px #e6b04b95;
        color: #333333;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 22px;
    }
    
    .c4r-button button:hover {
        transform: scale(1.05);
        background-image: linear-gradient(to bottom, #E6AF4B, #F9D49C);    transition: 0.5s ease;
        box-shadow: 0px 0px 5px #F9D49C;
    }




@media only screen and (max-width: 480px) {
    .seaHeaderText h1 {
        width: 80%;
        font-size: 36px;
    }

    .seaHeaderText h3 {
        font-size: 18px;
        width: 80%;
    }

    .content-1 {
        margin: 0;
        width: 100%;
    }

    .contentTitle {
        padding: 0;
        width: 90%;
    }
    .contentTitle h1 {
        font-size: 24px;
    }

    .contentTitle p {
        border-bottom: #E6AF4B 5px solid;
        padding: 12px;
        width: 100%;
        font-size: 18px;
    }

    .content2 {
        height: 100%;
        clip-path: none;      
    }

    .content-2 {
        flex-direction: column-reverse;
    }

    .content2::before {
        clip-path: circle(50% at -20% -20%);
    }

    .content-2Left {
        width: 90%;
    }
    .content-2Left h1{
        font-size: 24px;
        width: 100%;
    }

    .content-2Left h5 {
        width: 100%;
    }
    .content-2Left p{
        line-height: 1.15;
        width: 100%;
    }
    .content-2Left-More {
        width: 100%;
    }
    .content-2Right {
        width: 100%;
        height: auto;
    }

    .content-2Right img {
        overflow: hidden;
    }


    .content3 {
        height: 100%;
        clip-path: none;
    }
    .content-3 {
        flex-direction: column-reverse;
    }

    .content3::before {
        clip-path: circle(45% at 100% 100%);
    }
    .content-3Left {
        width: 100%;
        height: auto;
    }
    .content-3Left img {
        overflow: hidden;

    }
    .content-3Right {
        width: 100%;
    }

    .content-3Right h1 {
        font-size: 24px;
    }
    .content-3Right h5 {
        font-size: 18px;
    }
    .content-3Right ul {
        font-size: 18px;
    }
    .content-3Right li {
        font-size: 16px;
    }

    .content4 {
        height: 100%;
        clip-path: none;
    }

    .content-4 {
        flex-direction: column;
    }


    .content-4Left {
        width: 100%;
        height: auto;
    }

    .content-4Left img {
        overflow: hidden;
    }

    .content-4Right {
        width: 100%;
        justify-content: flex-start;
    }

    .content-4Right h1 {
        font-size: 24px;
        width: 100%;
        margin-left: 0;
        margin-top: 3rem;
        text-align: center;
    }
    .content-4Right ul {
        font-size: 18px;
    }
    .content-4Right li {
        font-size: 16px;
    }
}




