/* Header start */

.header {
    height: 88vh;
    display: flex;
    position: relative;
    background-color: #f4f6f8;
    z-index: 1;
    overflow: hidden;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1;
    padding: 20px;
}

.headerText {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    padding: 20px;
    gap: 12px;
}

.headerText h1 {
    color: #042B3B;
    font-size: 74px;
    margin: 0;
    font-weight: 500;
    font-style: italic;
}

.headerText h2 {
    color: #333;
    font-size: 36px;
    padding: 12px 0;
    font-weight: 400;
}

.headerText h3 {
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    width: 80%;
}

.headerText h5 {
    position: relative;
    color: #E6AF4B;
    font-size: 32px;
    padding-left: 10rem;
    font-style: italic;
    font-weight: 400;
}

.headerText h5::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 3px;
    background-color: #E6AF4B;
    left: 0;
    top: 1rem;
}

.homeBtn {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 12px;
}

.homeBtn button {
    color: white;
    background: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.scrollBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 4rem;
  }
  
  .curvedText {
    display: inline-block;
    font-size: 16px;
    color: #042B3B;
    transform: perspective(100px) rotateX(20deg); /* Creates a perspective effect */
    margin-bottom: 20px; /* Adjust as needed to move text closer to arrow */
  }
  
  .arrowDownIcon {
    color: #042B3B;
    cursor: pointer;
    font-size: 36px !important;
    animation: bounce 2s infinite;
    margin-top: -20px; /* Adjusts position to be just below the text */
  }

  .arrowDownIcon:hover {
    color: #E6AF4B;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  

  
.homeImg {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    position: relative;
}

.homeImg img {
    width: 70%; /* Control the size of the image */
    height: auto;
    box-shadow: 0 0 4px #333;
    border-radius: inherit; /* Ensures the image takes the same border-radius as the container */
    animation: profile_animate 8s ease-in-out infinite 2s; /* Apply animation directly to the image */

}

@keyframes profile_animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}



/* Partner testimonial */
.partnerLogo {
    background-color: #f4f6f8;
    height: 100%;
    width: 100%;
}



/* About Start */

.aboutHome {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f4f6f8;
    height: 100vh;
    margin-bottom: 2rem;
}

.aboutHomeText {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 50%;
}



.aboutHomeText h1 {
    color: #042B3B;
    width: 72%;
    font-weight: bold;
    font-size: 36px;
    z-index: 10;
}

.aboutHomeText h5 {
    color: #e6af4b;
    text-transform: uppercase;
    width: 72%;
    font-weight: bold;
    font-size: 24px;
    z-index: 10;
}

.aboutHomeText p {
    color: #042B3B;
    width: 70%;
    font-size: 18px;
    line-height: 1.5;
    z-index: 10;
    padding: 12px;
}


.aboutHomeImg {
    display: flex;
    align-items: center;
    position: relative; /* set position to relative */
    height: 100%;
    width: 50%;
  }
  
  .aboutHomeImg img {
    height: 80%;
    width: 100%;
    object-fit: cover;
  }
  

/* About End */



  .ourService-home {
    background-color: #f4f6f8;
    height: 100vh;
  }


/* Section 3 start */
.section3 {
    background-color: #f4f6f8;
    padding: 40px 20px;
}

.section3Title {
    text-align: center;
    margin-bottom: 20px;
}

.section3Title h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #042B3B;
}

.boxCardHome {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.cardHome {
    width: 100%;
    max-width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 40px;
    border-radius: 10px;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 10px;
    transition: transform 0.3s ease;
    background-color: #f0f2f5;
    color: #042B3B;
}

.cardHome:hover {
    background-color: #ffffff;
    transform: translateY(-5px); /* Slight lift effect on hover */
}

.cardTop {
    display: flex;
    width: 100%;
    height: 30%;
    overflow: hidden; 
}

.cardTop img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.cardContent {  
    width: 100%;
    height: 70%;
    text-align: left;
    padding: 0 10px;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.cardContent h3 {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    margin: 10px 0;
}

.cardContent p {
    font-size: 18px;
    margin: 5px 0;
    font-weight: 400;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.viewmore-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
}

.viewmore-btn button {
    padding: 10px 40px 14px;
    border-radius: 0.25rem;
    border: 2px solid transparent;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    color: white;
    background-color: #042B3B;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.538);
}

.viewmore-btn button:hover {
    background-color: #063A4F !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.homeFaqContainer {
    position: relative;
    height: 100%;
  }
  
  .homeFaqBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #E6AF4B 20%, #E6AF4B 80%);
    clip-path: ellipse(35% 35% at 90% 45%);
    z-index: 0;
  }
  
  .homeFaq {
    height: 100%;
    margin: 2rem 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  

/* Home form */

.homeForm {
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    height: 100%;
    width: 100%;
    background-image: url(../../images/eas-bg.jpg);
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-color: #042B3B;
}

.homeFormRight {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
}

/* Media queries for responsiveness */

@media only screen and (max-width: 1024px) {
    .header {
        flex-direction: column-reverse;
        height: 85vh;
    }

    .headerText {
        align-items: center;
        text-align: center;
    }

    .headerText h1 {
        font-size: 48px;
    }

    .headerText h2 {
        font-size: 28px;
    }

    .headerText h3 {
        font-size: 20px;
    }

    .headerText h5 {
        padding-left: 0;
        font-size: 24px;
        text-align: center;
    }

    .headerText h5::before {
        top: -10px;
        left: -55px;
        width: 200px;
    }

    .homeImg {
        width: 50%;
        height: auto;
    }

    .homeImg img {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .header {
        height: 100%;
    }
    .headerContainer {
        flex-direction: column-reverse;
    }
    .headerText h1 {
        font-size: 36px;
    }

    .headerText h2 {
        font-size: 24px;
    }

    .headerText h3 {
        font-size: 18px;
    }

    .headerText h5 {
        font-size: 20px;
    }

    .homeImg img {
        width: 100%;
        margin-top: 3rem;
    }

    .aboutHome {
        flex-direction: column;
        height: auto;
        margin-top: 3rem;
    }

    .aboutHomeText {
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .aboutHomeImg {
        width: 90%;
        margin-top: 20px;
    }

    .aboutHomeImg img {
        width: 100%;
        height: auto;
    }

    .cardHome {
        width: 100%;
        max-width: 400px;
        height: auto;
    }

    .section3Title h1 {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .headerText h1 {
        font-size: 28px;
    }

    .headerText h2 {
        font-size: 20px;
    }

    .headerText h3 {
        font-size: 16px;
    }

    .headerText h5 {
        font-size: 16px;
        padding-left: 0;
    }
    
    .headerText h5::before {
        width: 170px;
    }

    .homeImg {
        width: 100%;
    }

    .homeBtn {
        flex-direction: column;
    }

    .homeBtn button {
        padding: 5px 10px;
    }

    .scrollBtn {
        margin-top: 2rem;
    }

    .aboutHomeText h1 {
        font-size: 24px;
    }

    .aboutHomeText h5 {
        font-size: 18px;
    }

    .aboutHomeText p {
        font-size: 16px;
        width: 90%;
    }
    
    .ourService-home {
        height: 100%;
    }

    .cardHome {
        width: 100%;
        max-width: 300px;
    }

    .viewmore-btn button {
        padding: 5px 10px;
    }

    .section3Title h1 {
        font-size: 1.5rem;
    }
}
