.customsclearanceHeader {
    position: relative;
    height: 350px;
    width: 100%;
    background-color: #042B3B;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
  }
  
.customsclearanceHeaderText {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

 
.customsclearanceHeaderText h1 {
    font-size: 64px;
    color: #E6AF4B;
    margin-bottom: 1rem;
}

.customsclearanceHeaderText h3 {
    font-size: 28px;
    color: #ffffff;
    width: 70%;
}


.bg-bg {
    background-color: #e6af4b;
    z-index: 10;
}


.cc-container {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .ccBg {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.15;
  }

  .ccBg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cc-content {
    display: flex !important;
    flex-direction: row !important;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
  
  .left-box,
  .right-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 50%;
    height: 100%;
    padding: 20px;
  }
  
  .leftBox,
  .rightBox {
    width: 100%;
    height: 100%;
    padding: 12px;
    z-index: 1;
  }
  
  .leftBox h1,
  .rightBox h1 {
    text-align: left;
    font-size: 32px;
    color: #042B3B;
  }
  

  
  

.cc-content3 {
    width: 100%;
    height: 90vh;
    display: flex;
    position: relative;
    background-color: #f4f6f8; /* change background color to white */
    z-index: 0;
}

.cc-content3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1; /* set a lower z-index */
  }

.cc-content-3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 1;
}

.cc-content-3Left {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    max-height: 500px;
    
}


.cc-content-3Left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.cc-content-3Right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;
    color: #042B3B;

}

.cc-content-3Right h1 {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: bold;
    }

    .cc-content-3Right h5 {
        width: 80%;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .content-list {
        width: 80%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        text-align: left;
    }

    .content-list img {
        width: 32px;
        height: 32px;
    }

    .content-list span{
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 300;
        margin-left: 22px;
        line-height: 1.5;
    }
    


    .cc-content-3Right p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
        margin-left: 22px;
        line-height: 1.5;
    }

    .cc-c3r-button button {
        padding: 6px 12px;
        background-color: #E6AF4B;
        box-shadow: 0px 0px 5px #e6b04b95;
        color: #333333;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 22px;
    }
    
    .cc-c3r-button button:hover {
        transform: scale(1.05);
        background-image: linear-gradient(to bottom, #E6AF4B, #F9D49C);    transition: 0.5s ease;
        box-shadow: 0px 0px 5px #F9D49C;
    }


    .cc-content4 {
        width: 100%;
        height: 90vh;
        display: flex;
        position: relative;
        background-color: #f4f6f8;
        z-index: 0;
    }
    
    .cc-content4::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1; /* set a lower z-index */
        clip-path: circle(30% at 27% 50%);
        background-color: #e6af4b;
      }
    
    .cc-content-4 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        position: relative;
        z-index: 1;
    }
    
    .cc-content-4Left {
        display: flex;
        width: 50%;
        height: auto;
    }
    
    
    .cc-content-4Left img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .cc-content-4Right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 50%;
        height: 100%;
        color: #042B3B;
    
    }
    
    .cc-content-4Right h1 {
        font-size: 36px;
        margin-bottom: 30px;
        font-weight: bold;
        }
    
        .cc-content-4Right p {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 10px;
            padding-left: 20px;
            line-height: 1.5;
        }
        
        .cc-content-4Right ul {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 400;
        }
        
        .cc-content-4Right li {
        font-size: 18px;
        margin-bottom: 10px;
        padding-left: 20px;
        text-indent: -10px;
        font-weight: 300;
        }
        







@media only screen and (max-width: 480px) {
    .customsclearanceHeaderText h1 {
        width: 80%;
        font-size: 36px;
    }

    .customsclearanceHeaderText h3 {
        font-size: 18px;
        width: 80%;
    }


    .cc-content {
        flex-direction: column !important;
        height: 100%;
    }

    .left-box {
        width: 100%;
        padding: 0;
    }

    .leftBox {
        height: 100%;
    }

    .leftBox h1 {
        font-size: 24px;
    }

    .right-box {
        width: 100%;
        padding: 0;
    }

    .rightBox {
        height: 100%;
    }
    
    .rightBox h1 {
        font-size: 24px;
    }
    
    .cc-content3 {
        height: 100%;
        clip-path: none;
    }

    .cc-content3::before {
        clip-path: polygon(0 0, 100% 13%, 100% 100%, 0 100%);
      }
    .cc-content-3 {
        flex-direction: column;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .cc-content-3Left {
        width: 100%;
        height: 100%;
    }
    .cc-content-3Left img {
        overflow: hidden;

    }
    .cc-content-3Right {
        width: 90%;
    }

    .cc-content-3Right h1 {
        margin-top: 36px;
        font-size: 24px;
    }

    .cc-content-3Right h5 {
        font-size: 18px;
        font-weight: 400;
        width: 100%;
    }

    .content-list {
        width: 100%;
    }

    .content-list span {
        font-size: 16px;
    }




    .cc-content4 {
        height: 100%;
        clip-path: none;
    }


    .cc-content4::before {
        clip-path: circle(90% at 50% 100%);    }

    .cc-content-4 {
        flex-direction: column;
    }

    .cc-content-4Left {
        width: 100%;
        height: 100%;
    }

    .cc-content-4Left img {
        overflow: hidden;
    }

    .cc-content-4Right {
        width: 100%;
        justify-content: flex-start;
    }

    .cc-content-4Right h1 {
        font-size: 32px;
    }
    .cc-content-4Right ul {
        font-size: 18px;
    }
    .cc-content-4Right li {
        font-size: 16px;
    }

    .c4r-button-button {
        width: 100%;
    }

    .content5 {
        height: 100%;
        clip-path: none;      
    }

    .cc-content-5 {
        flex-direction: column;
    }

    .cc-content5::before {
        clip-path: circle(45% at 50% 50%);
    }

    .cc-content-5Left {
        width: 100%;
    }

    .cc-content-5Left img {
        overflow: hidden;
    }

    .cc-content-5Right {
        width: 100%;
        justify-content: flex-start;
    }

    .cc-content-5Right h1 {
        font-size: 32px;
    }
    .cc-content-5Right ul {
        font-size: 18px;
    }
    .cc-content-5Right li {
        font-size: 16px;
    }


}


























.customclearanceContent {
    height: 100%;
    display: flex;
    position: relative;

}

.customclearanceContentTitle {
    margin-top: 12px;
    width: 100%;
    height: 100%;
}

.customclearanceContentTitle h1 {
    font-size: 32px;
    text-align: left;
    color: #042B3B;
    font-weight: bold;
    padding: 0 12px;
}

.customclearanceContentTitle h3 {
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #042B3B;
    padding: 0 12px;
}



.importCc {
    margin: 12px 0;
    padding: 12px 0;
}

.importCc h1 {
    font-weight: 400;
    font-size: 24px;
}

.exportCc {
    margin: 12px 0;
    padding: 12px 0;
}

.exportCc h1 {
    font-weight: 400;
    font-size: 24px;
}

.customclearanceContent li {
    list-style-type: circle;
    padding: 8px 0;
    color: #042B3B;

}



@media only screen and (max-width: 480px ){
    .customclearanceText {
        font-size: 36px;
    }

    .customclearanceContentTitle {
        height: 100%;
    }

    .customclearanceContentTitle h1 {
        font-size: 24px;
    }
    .importCc h1 {
        font-size: 18px;
        padding: 12px;
    }
}